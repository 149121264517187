<template>
  <div class="page-full">
    <div class="image-login">
      <img src="@/assets/logo-lifeapps.png" alt="" />
    </div>
    <Card class="card-oauth" width="400px" shadown :loading="isLoading">
      <template v-if="isLoading && !config">
        <h3>Configurando integração</h3>
        <p>Aguarde até que a configuração seja efetivada</p>
      </template>
      <template v-else>
        <div class="service-image">
          <img :src="config.logo_url" :alt="config.nome" />
        </div>
        <h3>Autenticação concluida!</h3>
        <p>A integração com o serviço {{ config.nome }} foi concluida</p>
        <FormButton
          full
          text="Voltar para tela de integração"
          to="/fornecedor/configuracao/integracoes"
          type="submissao"
        />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import FormButton from '@/components/form/FormButton'
import { OAUTH_CALLBACK } from '@/store/actions/oauth'
import { LOADING_HIDE, LOADING_SHOW } from '@/store/actions/loading'
export default {
  name: 'OauthCallback',
  components: { Card, FormButton },
  data: () => ({
    isLoading: true,
    error: '',
    config: null
  }),
  created() {
    if (this.$store.getters.isAuthenticated) {
      if (this.$store.getters.permissoesLoaded) {
        return this.saveCallback()
      } else {
        this.$store.dispatch(LOADING_SHOW)
        this.$store.watch(
          () => this.$store.getters.getPermissoes,
          () => {
            this.$store.dispatch(LOADING_HIDE)
            return this.saveCallback()
          }
        )
      }
    }
  },
  methods: {
    saveCallback() {
      const { params, query } = this.$route
      this.$store
        .dispatch(OAUTH_CALLBACK, {
          service: params.service,
          params: query
        })
        .then(data => {
          this.config = data
          this.isLoading = false
        })
        .catch(() => (this.isLoading = false))
    }
  }
}
</script>

<style lang="scss">
.page-full {
  min-height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .image-login {
    width: 250px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 10px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
  .card-oauth {
    padding: 10px;
  }
  .service-image {
    width: 100%;
    img {
      max-width: 100%;
      max-height: 100px;
      width: auto;
      height: auto;
    }
  }
}
</style>
